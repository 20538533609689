import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormularioBase } from 'src/app/shared/pages/FormularioBase';
import { MasterService } from 'src/app/shared/services/master.service';
import { UsuariosService } from 'src/app/shared/services/usuarios.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { ToastrService } from 'ngx-toastr';
import { Constantes } from 'src/app/shared/utils/Constantes';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
  selector: 'app-activar-usuario',
  templateUrl: './activar-usuario.component.html',
  styleUrls: ['./activar-usuario.component.css']
})
export class ActivarUsuarioComponent extends FormularioBase implements OnInit {

  key:string;
  disabledButtonLogin:boolean = true;
  esperandoConfirmacion = true;
  message: string;

  constructor( 
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    public spinner: NgxSpinnerService,
    public utilService: UtilService,
    public masterService: MasterService,
    public usuariosService: UsuariosService,
    private toastr: ToastrService,
    public sessionService: SessionService) {

    super('activar-usuario', dialog, route, router, spinner, utilService, masterService, sessionService);
  }  

  ngOnInit(): void 
  {
    this.ConfirmarActivacion();    
  }

  ConfirmarActivacion()
  {
    this.mostrarProgreso();
    this.route.queryParams.subscribe(params => {
      
      const userId = params['key'];      

      if(!userId){
        this.ocultarProgreso();
        this.toastr.error('Redireccionando!', '!Error!', {
          timeOut: 5000,
          progressBar: true,
          progressAnimation: 'increasing'
        }).onHidden.subscribe(()=>{
          
          this.router.navigate([Constantes.ruteo.InicioSesion]);
        });
      }

      var key =  atob(userId);
    
      this.usuariosService.activarUsuario(key).then(response =>{
        
        this.esperandoConfirmacion = false;

        if(response.data === "OK" )
        {
          this.disabledButtonLogin = false;
          this.toastr.success('Activación de usuario exitosa.', '!Correcto!', {
            timeOut: 2000,
            progressBar: true,
            progressAnimation: 'increasing'
          }).onHidden.subscribe(()=>{
            this.ocultarProgreso();            
          });
        }
        else{
          this.toastr.error(response.data, '!Error!', {
            timeOut: 2000,
            progressBar: true,
            progressAnimation: 'increasing'
          }).onHidden.subscribe(()=>{
            this.ocultarProgreso();
            this.router.navigate([Constantes.ruteo.InicioSesion]);
          });
          
        }
      });      
    });
  }

}
