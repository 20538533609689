import { Component, ElementRef, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { AccesoUsuario } from '../../models/response/acceso-usuario';
import { filter } from 'rxjs/operators';
import { Constantes } from '../../utils/Constantes';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  private listTitles: any[] = [];
  location: Location;
  private mobile_menu_visible = 0;
  private toggleButton: any;
  private sidebarVisible: boolean;
  private $layer: any = null;
  nombreUsuario = '';

  constructor(location: Location,  
              private element: ElementRef, 
              private router: Router,
              public sessionService: SessionService) {
    this.location = location;
    this.sidebarVisible = false;

    router.events.pipe(
        filter(event => event instanceof NavigationEnd)
        )
        .subscribe((event: any) => 
        {
            const localStorageValue = sessionService.Usuario;
    
            if(localStorageValue != null)
            {
                this.nombreUsuario = localStorageValue.nombreUsuario;
            }
        });
  }

  ngOnInit(){
    //this.listTitles = ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
        this.$layer = document.getElementsByClassName('close-layer')[0];
        if (this.$layer) {
          this.$layer.remove();
          this.mobile_menu_visible = 0;
        }
    });
  }

  sidebarOpen() {
      const toggleButton = this.toggleButton;
      const body = document.getElementsByTagName('body')[0];
      setTimeout(function(){
          toggleButton.classList.add('toggled');
      }, 500);

      body.classList.add('nav-open');

      this.sidebarVisible = true;
  };
  sidebarClose() {
      const body = document.getElementsByTagName('body')[0];
      this.toggleButton.classList.remove('toggled');
      this.sidebarVisible = false;
      body.classList.remove('nav-open');
  };
  sidebarToggle() {
      // const toggleButton = this.toggleButton;
      // const body = document.getElementsByTagName('body')[0];
      var $toggle = document.getElementsByClassName('navbar-toggler')[0];

      if (this.sidebarVisible === false) {
          this.sidebarOpen();
      } else {
          this.sidebarClose();
      }
      const body = document.getElementsByTagName('body')[0];

      if (this.mobile_menu_visible == 1) {
          // $('html').removeClass('nav-open');
          body.classList.remove('nav-open');
          if (this.$layer) {
              this.$layer.remove();
          }
          setTimeout(function() {
              $toggle.classList.remove('toggled');
          }, 400);

          this.mobile_menu_visible = 0;
      } else {
          setTimeout(function() {
              $toggle.classList.add('toggled');
          }, 430);

          var $layer = document.createElement('div');
          $layer.setAttribute('class', 'close-layer');


          if (body.querySelectorAll('.main-panel')) {
              document.getElementsByClassName('main-panel')[0].appendChild($layer);
          }else if (body.classList.contains('off-canvas-sidebar')) {
              document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
          }

          setTimeout(function() {
              $layer.classList.add('visible');
          }, 100);

          $layer.onclick = () =>
          {
            body.classList.remove('nav-open');
            this.mobile_menu_visible = 0;
            $layer.classList.remove('visible');
            setTimeout(function() {
                $layer.remove();
                $toggle.classList.remove('toggled');
            }, 400);
          };

          body.classList.add('nav-open');
          this.mobile_menu_visible = 1;

      }
  };

  OpenMenu()
  {
    var $wrapper = document.getElementsByClassName('wrapper')[0];

    if ($wrapper.classList.contains('sidebar-mini')) 
    {
        $wrapper.classList.remove('sidebar-mini');
    }
    else
    {
        $wrapper.classList.add('sidebar-mini');
    }
  }

  getTitle()
  {
    var titlee = this.location.prepareExternalUrl(this.location.path());

    if(titlee.charAt(0) === '#'){
        titlee = titlee.slice( 1 );
    }

    const opciones = this.sessionService.Opciones;
    this.listTitles = opciones;

    if(opciones === null) return '';

    for(var item = 0; item < this.listTitles.length; item++)
    {
        if(titlee.startsWith(this.listTitles[item].Ruta))
        {
            return this.listTitles[item].Nombre;
        } 

        for(var itemSubMenu = 0; itemSubMenu < this.listTitles[item].Opciones.length; itemSubMenu++)
        {
            if(titlee.startsWith(this.listTitles[item].Opciones[itemSubMenu].Ruta)){
                return this.listTitles[item].Opciones[itemSubMenu].Nombre;
            }
        }
    }
    return '';
  }

  onCerrarSesion()
  {
    this.sessionService.CerrarSession();
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    this.router.navigate([Constantes.ruteo.Home]));
  }

}
